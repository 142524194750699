export default () => ({
    alertFetchedFrom: null,
    alertFetchedTo: null,
    alertDateFrom: null,
    alertDateTo: null,
    alertLive: true,
    alertFilter: {
        severity: [],
        category: []
    },
    alertConnection: null,
    alertFetched: false,
    reportDateFrom: null,
    reportDateTo: null,
    reportLive: true,
    reportWarehouse: null
});
