<template>
    <div :class="{ invisible: !isShow }" class="q-gutter-sm q-ml-none">
        <slot />
    </div>
</template>

<script setup>
    import { ref, onUnmounted } from 'vue';

    const props = defineProps({
        delay: {
            type: Number,
            default: 250
        }
    });

    const isShow = ref(false);
    const timeout = setTimeout(() => {
        isShow.value = true;
    }, props.delay);

    onUnmounted(() => {
        clearTimeout(timeout);
    });
</script>
