const routes = [
    {
        path: '/',
        component: () => import('layouts/MainLayout.vue'),
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('pages/Index.vue')
            },
            {
                path: 'label-status',
                component: () => import('pages/LabelStatus.vue'),
                children: [
                    {
                        path: '',
                        name: 'labelStatus',
                        component: () => import('pages/LabelStatusMap.vue')
                    },
                    {
                        path: 'indoor/:id',
                        name: 'labelStatusIndoor',
                        component: () => import('pages/LabelStatusIndoor.vue'),
                        children: [
                            {
                                path: 'list',
                                name: 'labelStatusIndoorList',
                                component: () => import('pages/LabelStatusIndoorList.vue'),
                                meta: {
                                    breadcrumbs(route, store) {
                                        const warehouse = store.state.nodeState.warehouses.find(
                                            el => el.id === route.params.id
                                        );
                                        return [
                                            {
                                                label: 'Dashboard',
                                                to: 'dashboard'
                                            },
                                            {
                                                label: 'Label Status and Location',
                                                to: 'labelStatus'
                                            },
                                            {
                                                label: `Warehouse ${warehouse?.name}`
                                            }
                                        ];
                                    }
                                }
                            }
                        ],
                        meta: {
                            indoor: true
                        }
                    },
                    {
                        path: 'list',
                        name: 'labelStatusList',
                        component: () => import('pages/LabelStatusList.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Label Status and Location',
                                    to: 'labelStatus'
                                },
                                {
                                    label: 'Label Status'
                                }
                            ]
                        }
                    },
                    {
                        path: 'shipments',
                        name: 'labelStatusShipment',
                        component: () => import('pages/LabelStatusShipment.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Label Status and Location',
                                    to: 'labelStatus'
                                },
                                {
                                    label: 'Shipment'
                                }
                            ]
                        }
                    },
                    {
                        path: 'shipments/:id',
                        name: 'labelStatusShipmentList',
                        component: () => import('pages/LabelStatusShipmentList.vue'),
                        meta: {
                            breadcrumbs(route) {
                                return [
                                    {
                                        label: 'Dashboard',
                                        to: 'dashboard'
                                    },
                                    {
                                        label: 'Label Status and Location',
                                        to: 'labelStatus'
                                    },
                                    {
                                        label: 'Shipment',
                                        to: 'labelStatusShipment'
                                    },
                                    {
                                        label: route.params.id
                                    }
                                ];
                            }
                        }
                    }
                ]
            },
            {
                path: 'label-provisioning',
                component: () => import('pages/LabelProvisioning.vue'),
                children: [
                    {
                        path: ':filter',
                        name: 'labelProvisioning',
                        component: () => import('pages/LabelProvisioningList.vue'),
                        meta: {
                            breadcrumbs(route) {
                                return [
                                    {
                                        label: 'Dashboard',
                                        to: 'dashboard'
                                    },
                                    {
                                        label: 'Label Provisioning and Management'
                                    },
                                    {
                                        label: `${route.params.filter}`
                                    }
                                ]
                            }
                        },
                    },
                    {
                        path: '/label-provisioning/:filter/add',
                        name: 'labelProvisioningAdd',
                        component: () => import('pages/LabelProvisioningAdd.vue'),
                        meta: {
                            breadcrumbs(route) {
                                return [
                                    {
                                        label: 'Dashboard',
                                        to: 'dashboard'
                                    },
                                    {
                                        label: 'Label Provisioning and Management',
                                        to: 'labelProvisioning'
                                    },
                                    {
                                        label: `${route.params.filter}`,
                                        to: { name: 'labelProvisioning', params: { filter: route.params.filter } }
                                    },
                                    {
                                        label: 'Add'
                                    }
                                ]
                            }
                        }
                    },
                    {
                        path: '/label-provisioning/:filter/:id',
                        name: 'labelProvisioningEdit',
                        component: () => import('pages/LabelProvisioningEdit.vue'),
                        meta: {
                            breadcrumbs(route) {
                                return [
                                    {
                                        label: 'Dashboard',
                                        to: 'dashboard'
                                    },
                                    {
                                        label: 'Label Provisioning and Management',
                                        to: 'labelProvisioning'
                                    },
                                    {
                                        label: `${route.params.filter}`,
                                        to: { name: 'labelProvisioning', params: { filter: route.params.filter } }
                                    },
                                    {
                                        label: `${route.params.id}`
                                    }
                                ];
                            }
                        }
                    },
                    {
                        path: 'shipments',
                        component: () => import('pages/LabelProvisioningShipment.vue'),
                        children: [
                            {
                                path: '',
                                name: 'labelProvisioningShipment',
                                component: () => import('pages/LabelProvisioningShipmentList.vue'),
                                meta: {
                                    breadcrumbs: [
                                        {
                                            label: 'Dashboard',
                                            to: 'dashboard'
                                        },
                                        {
                                            label: 'Label Provisioning and Management',
                                            to: 'labelProvisioning'
                                        },
                                        {
                                            label: 'Shipments'
                                        }
                                    ]
                                }
                            },
                            {
                                path: 'add',
                                name: 'labelProvisioningShipmentAdd',
                                component: () => import('pages/LabelProvisioningShipmentAdd.vue'),
                                meta: {
                                    breadcrumbs: [
                                        {
                                            label: 'Dashboard',
                                            to: 'dashboard'
                                        },
                                        {
                                            label: 'Label Provisioning and Management',
                                            to: 'labelProvisioning'
                                        },
                                        {
                                            label: 'Shipments',
                                            to: 'labelProvisioningShipment'
                                        },
                                        {
                                            label: 'Add Shipment'
                                        }
                                    ]
                                }
                            },
                            {
                                path: ':id',
                                name: 'labelProvisioningShipmentEdit',
                                component: () => import('pages/LabelProvisioningShipmentEdit.vue'),
                                meta: {
                                    breadcrumbs(route) {
                                        return [
                                            {
                                                label: 'Dashboard',
                                                to: 'dashboard'
                                            },
                                            {
                                                label: 'Label Provisioning and Management',
                                                to: 'labelProvisioning'
                                            },
                                            {
                                                label: 'Shipments',
                                                to: 'labelProvisioningShipment'
                                            },
                                            {
                                                label: `Shipment ${route.params.id}`
                                            }
                                        ];
                                    }
                                }
                            }
                        ]
                    },
                    {
                        path: 'transports',
                        component: () => import('pages/LabelProvisioningTransport.vue'),
                        children: [
                            {
                                path: '',
                                name: 'labelProvisioningTransport',
                                component: () => import('pages/LabelProvisioningTransportList.vue'),
                                meta: {
                                    breadcrumbs: [
                                        {
                                            label: 'Dashboard',
                                            to: 'dashboard'
                                        },
                                        {
                                            label: 'Label Provisioning and Management',
                                            to: 'labelProvisioning'
                                        },
                                        {
                                            label: 'Transports'
                                        }
                                    ]
                                }
                            },
                            {
                                path: 'add',
                                name: 'labelProvisioningTransportAdd',
                                component: () => import('pages/LabelProvisioningTransportAdd.vue'),
                                meta: {
                                    breadcrumbs: [
                                        {
                                            label: 'Dashboard',
                                            to: 'dashboard'
                                        },
                                        {
                                            label: 'Label Provisioning and Management',
                                            to: 'labelProvisioning'
                                        },
                                        {
                                            label: 'Transports',
                                            to: 'labelProvisioningTransport'
                                        },
                                        {
                                            label: 'Add Transport'
                                        }
                                    ]
                                }
                            },
                            {
                                path: ':id',
                                name: 'labelProvisioningTransportEdit',
                                component: () => import('pages/LabelProvisioningTransportEdit.vue'),
                                meta: {
                                    breadcrumbs(route) {
                                        return [
                                            {
                                                label: 'Dashboard',
                                                to: 'dashboard'
                                            },
                                            {
                                                label: 'Label Provisioning and Management',
                                                to: 'labelProvisioning'
                                            },
                                            {
                                                label: 'Transports',
                                                to: 'labelProvisioningTransport'
                                            },
                                            {
                                                label: `Transport ${route.params.id}`
                                            }
                                        ];
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'reporting',
                component: () => import('pages/Reporting.vue'),
                children: [
                    {
                        path: '',
                        name: 'reporting',
                        component: () => import('pages/ReportingList.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Reports'
                                }
                            ]
                        }
                    },
                    {
                        path: ':name',
                        name: 'ExecutionHistory',
                        component: () => import('pages/ExecutionHistory.vue'),
                        meta: {
                            breadcrumbs(route) {
                                return [
                                    {
                                        label: 'Dashboard',
                                        to: 'dashboard'
                                    },
                                    {
                                        label: 'Reports',
                                        to: 'reporting'
                                    },
                                    {
                                        label: `${route.params.name}`
                                    }
                                ];
                            }
                        }
                    }
                ]
            },
            {
                path: 'mesh-bridge-admin',
                component: () => import('pages/MeshBridge.vue'),
                children: [
                    {
                        path: '',
                        name: 'meshBridgeAdmin',
                        component: () => import('pages/MeshBridgeList.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Mesh Bridge Administration'
                                }
                            ]
                        }
                    },
                    {
                        path: 'add',
                        name: 'addMeshBridge',
                        component: () => import('pages/MeshBridgeAdd.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Mesh Bridge Administration',
                                    to: 'meshBridgeAdmin'
                                },
                                {
                                    label: 'Add Mesh Bridge'
                                }
                            ]
                        }
                    },
                    {
                        path: ':id',
                        name: 'editMeshBridge',
                        component: () => import('pages/MeshBridgeEdit.vue'),
                        meta: {
                            breadcrumbs(route) {
                                return [
                                    {
                                        label: 'Dashboard',
                                        to: 'dashboard'
                                    },
                                    {
                                        label: 'Mesh Bridge Administration',
                                        to: 'meshBridgeAdmin'
                                    },
                                    {
                                        label: `Mesh Bridge ${route.params.id}`
                                    }
                                ];
                            }
                        }
                    }
                ]
            },
            {
                path: 'api-admin',
                name: 'apiAdmin',
                component: () => import('pages/ApiAdmin.vue')
            },
            {
                path: 'user-admin',
                name: 'userAdmin',
                component: () => import('pages/UserAdmin.vue')
            },
            {
                path: 'localization',
                component: () => import('pages/IndoorLocalization.vue'),
                children: [
                    {
                        path: '',
                        name: 'indoorLocalization',
                        component: () => import('pages/IndoorLocalizationList.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Indoor Localizations'
                                }
                            ]
                        }
                    },
                    {
                        path: 'add',
                        name: 'indoorLocalizationAdd',
                        component: () => import('pages/IndoorLocalizationAdd.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Indoor Localizations',
                                    to: 'indoorLocalization'
                                },
                                {
                                    label: 'Add Indoor Localization'
                                }
                            ]
                        }
                    },
                    {
                        path: ':id',
                        name: 'indoorLocalizationEdit',
                        component: () => import('pages/IndoorLocalizationEdit.vue'),
                        meta: {
                            breadcrumbs(route, store, data) {
                                return [
                                    {
                                        label: 'Dashboard',
                                        to: 'dashboard'
                                    },
                                    {
                                        label: 'Indoor Localizations',
                                        to: 'indoorLocalization'
                                    },
                                    {
                                        label: data
                                    }
                                ];
                            }
                        }
                    }
                ]
            },
            {
                path: 'billing',
                component: () => import('pages/Billing.vue'),
                children: [
                    {
                        path: '',
                        name: 'billing',
                        component: () => import('pages/BillingSummary.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Billing Summary',
                                },
                                {
                                    label: 'Billing'
                                }
                            ]
                        }
                    },
                    {
                        path: 'active-devices',
                        name: 'activeDevicesList',
                        component: () => import('pages/ActiveDevicesList.vue'),
                        meta: {
                            breadcrumbs: [
                                {
                                    label: 'Dashboard',
                                    to: 'dashboard'
                                },
                                {
                                    label: 'Billing Summary',
                                },
                                {
                                    label: 'Active Devices'
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    },
    //	{
    //		path: '/login/',
    //		component: () => import('layouts/LoginLayout.vue')
    //	},

    // Always leave this as last one,
    // but you can also remove it
    {
        path: '/:catchAll(.*)*',
        name: 'error',
        component: () => import('pages/Error404.vue')
    }
];

export default routes;
