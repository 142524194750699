export const setAlertFetchedFrom = (state, data) => {
    state.alertFetchedFrom = data;
};

export const setAlertFetchedTo = (state, data) => {
    state.alertFetchedTo = data;
};
export const setAlertDateFrom = (state, data) => {
    state.alertDateFrom = data;
};

export const setAlertDateTo = (state, data) => {
    state.alertDateTo = data;
};

export const setAlertLive = (state, data) => {
    state.alertLive = data;
};

export const setAlertFilter = (state, data) => {
    state.alertFilter = data;
};

export const setAlertFetched = (state, data) => {
    state.alertFetched = data;
};

export const setAlertConnection = (state, data) => {
    state.alertConnection = data;
};

export const setReportDateFrom = (state, data) => {
    state.reportDateFrom = data;
};

export const setReportDateTo = (state, data) => {
    state.reportDateTo = data;
};

export const setReportLive = (state, data) => {
    state.reportLive = data;
};

export const setReportWarehouse = (state, data) => {
    state.reportWarehouse = data;
};
