export default () => ({
    alerts: [],
    labels: [],
    trackers: [],
    meshBridges: [],
    nodeStates: [],
    remoteLocations: [],
    warehouses: [],
    crlSummaryReport: [],
    warehousesSummaryReport: [],
    labelRoundTripsReport: [],
    shipments: [],
    transports: [],
    mobileAnchors: [],
    zones:[],
    mobileTrackerHistory:[],
    uniqueMobileTrackers:[],
    selectedHistoryLocation:null,
    selectedHistoryLocationFromList:null,
    sensorHistoryPopupDetail: {show: false},
    sensorTempGraph: { show: false },
    sensorBatGraph: { show: false },
    sensorData:[],
    eventsData: [],
    eventsFetched: false,
    sensorDataFetched: false,
    historyFilter: {},
    zoneHistory: [],
    zoneHistorySettings: {}
});
