export const crlTemplate = {
    name: '',
    sw_lat: '',
    ne_lat: '',
    sw_lon: '',
    ne_lon: ''
};

export const warehouseTemplate = {
    ...crlTemplate,
    mesh_bridges: []
};

export const locationToFront = target => {
    const location = { ...target };
    (location.id = location.warehouse_id || location.crl_id), (location.name = location.name || location.crl_name);

    if (location.crl_id) {
        delete location.crl_id;
        delete location.crl_name;
    } else {
        delete location.warehouse_id;
    }

    return location;
};

export const locationFromFront = target => {
    const location = { ...target };

    if (!location.mesh_bridges) {
        location.crl_name = location.name;
        delete location.name;
    }

    return location;
};

export const rectIntersect = (location, locations) => {
    function isIntersect(a, b) {
        if (b.sw_lon > a.ne_lon || b.ne_lon < a.sw_lon) return false;
        if (b.ne_lat < a.sw_lat || b.sw_lat > a.ne_lat) return false;
        return true;
    }

    return locations.find(el => el.id !== location.id && isIntersect(el, location));
};

export const getRectangle = ({ north_east, south_west }) => {
    let { lat: neLat, lng: neLng } = north_east;
    let { lat: swLat, lng: swLng } = south_west;
    const isGoogleTyped = typeof north_east.lat == 'function';

    if (isGoogleTyped) {
        neLat = north_east.lat();
        neLng = north_east.lng();
        swLat = south_west.lat();
        swLng = south_west.lng();
    }

    if (![neLat, neLng, swLat, swLng].every(c => c)) return [];

    const ne = isGoogleTyped
        ? {
              lat: north_east.lat(),
              lng: north_east.lng()
          }
        : north_east;

    const sw = isGoogleTyped
        ? {
              lat: south_west.lat(),
              lng: south_west.lng()
          }
        : south_west;

    return [{ lat: neLat, lng: swLng }, { ...ne }, { lat: swLat, lng: neLng }, { ...sw }];
};

export const moveMarker = (node, marker, numDeltas = 30) => {
    return new Promise(resolve => {
        const [lat, lng] = [node.lat, node.lon];
        let { lat: curLat, lng: curLng } = marker.getPosition();
        [curLat, curLng] = [curLat(), curLng()];

        const deltaLat = (lat - curLat) / numDeltas;
        const deltaLng = (lng - curLng) / numDeltas;
        let i = 1,
            done = false;

        function move() {
            curLat += deltaLat;
            curLng += deltaLng;

            const latLng = new google.maps.LatLng(curLat, curLng);
            marker.setPosition(latLng);
            if (i !== numDeltas) {
                i++;
                return !done ? setTimeout(move) : null;
            } else {
                done = true;
                resolve(latLng);
            }
        }
        move();
    });
};
