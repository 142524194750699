export const resizableDirective = {
    mounted(el, binding) {
      const resizeHandle = document.createElement('div');

      const resizeHandleStyles = {
        width: '15px',
        height: '15px',
        cursor: 'se-resize',
        position: 'absolute',
        bottom: '0',
        right: '0',
        background: 'repeating-linear-gradient( -45deg,  black,  black 2.5px, #F3F4F6 4px, #F3F4F6 8px)',
      };
      
      for (let style in resizeHandleStyles) {
        resizeHandle.style[style] = resizeHandleStyles[style];
      }

      el.appendChild(resizeHandle);

      let isResizing = false;
      let startX, startY, startWidth, startHeight;
  
      const resize = (e) => {
        if (!isResizing) return;
        document.body.style.userSelect = 'none';
        document.querySelector('.q-field__native').style.userSelect = 'none';

        const newWidth = startWidth + e.clientX - startX;
        let newHeight = startHeight + e.clientY - startY;

        if (binding.value['restrictMaxSize'] && (el.style.maxHeight === '' || el.style.maxWidth === '')) {
          el.style.maxHeight = el.style.height;
          el.style.maxWidth = el.style.width;
        }

        // Extract numeric values from minWidth, minHeight, maxWidth, and maxHeight
        const minWidth = Number(el.style.minWidth.replace('px', '')) || 0;
        const minHeight = Number(el.style.minHeight.replace('px', '')) || 0;
        const maxWidth = Number(el.style.maxWidth.replace('px', '')) || Infinity;
        const maxHeight = Number(el.style.maxHeight.replace('px', '')) || Infinity;

        if (binding.value['aspected']) {
          const aspectRatio = Number(el.style.width.replace('px', '')) / Number(el.style.height.replace('px', ''));
          newHeight = newWidth * aspectRatio;
        }

        // Ensure newWidth and newHeight are within the specified constraints
        el.style.width = Math.max(minWidth, Math.min(newWidth, maxWidth)) + 'px';
        el.style.height = Math.max(minHeight, Math.min(newHeight, maxHeight)) + 'px';
      };
  
      const stopResize = () => {
        if (!isResizing) return;
        document.body.style.userSelect = '';
        document.querySelector('.q-field__native').style.userSelect = '';

        isResizing = false;
        document.removeEventListener('mousemove', resize);
        document.removeEventListener('mouseup', stopResize);
        const resizeEndEvent = new Event('resizeend');
        el.dispatchEvent(resizeEndEvent);
      };
  
      resizeHandle.addEventListener('mousedown', (e) => {
        isResizing = true;
        startX = e.clientX;
        startY = e.clientY;
        startWidth = el.offsetWidth;
        startHeight = el.offsetHeight;
        document.addEventListener('mousemove', resize);
        document.addEventListener('mouseup', stopResize);

        const resizeStartEvent = new Event('resizestart');
        el.dispatchEvent(resizeStartEvent);
      });
    },
  };
  