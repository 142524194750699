<template>
    <q-breadcrumbs
        v-if="breadcrumbs.length"
        gutter="xs"
        active-color="grey"
        separator-color="grey"
        class="q-mb-md u-text-sm"
    >
        <q-breadcrumbs-el
            v-for="item in breadcrumbs"
            :key="item.to"
            :label="item.label"
            :to="push(item.to)"
            :class="{
                'text-dark': !item.to
            }"
        />
    </q-breadcrumbs>
</template>

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';

    const props = defineProps({
        data: {
            type: [Object, String],
            default: null
        }
    });

    const $route = useRoute();
    const $store = useStore();
    const breadcrumbs = computed(() => {
        if (!$route.meta?.breadcrumbs) {
            return [];
        }

        if (typeof $route.meta.breadcrumbs === 'function') {
            return $route.meta.breadcrumbs($route, $store, props.data);
        }

        return $route.meta.breadcrumbs;
    });

    const push = to => {
        if (!to) {
            return null;
        }

        if (typeof to === 'string') {
            return {
                name: to
            };
        }

        return to;
    };
</script>

<style lang="scss">
    .q-breadcrumbs {
        .q-router-link--active {
            transition: color 0.2s;

            &:hover {
                color: $dark;
            }
        }
    }
</style>
