import moment from 'moment';
import axios from 'axios';

import { ReportsApi } from 'src/services/api';
import { axiosErrorHandler } from 'src/services/errors';

export const getAllReports = async ({ commit }) => {
    try {
        const { data } = await ReportsApi.get();
        if (data.scripts) {
            commit('setReportsData', data);
        } else {
            commit('setReportsData', { scripts: [] });
        }
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getGeneratedReportFiles = async ({ commit }, reportId) => {
    try {
        const { data } = await ReportsApi.getFiles(reportId);
        if (data.report_files) {
            commit('setGeneratedReports', data);
        } else {
            commit('setGeneratedReports', { report_files: [] });
        }
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getReportsStatus = async ({ commit }) => {
    try {
        const { data } = await ReportsApi.getHistory({ report: 'ALL', duration: '24' });
        commit("setReportsStatus", data);
        commit("setReportsNotifications", data)
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getExecutionHistory = async ({ commit }, { reportName }) => {
    try {
        const { data } = await ReportsApi.getHistory({ report: reportName, duration: '720' });

        commit("setExecutionHistory", data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getReportParams = async ( context, reportName ) => {
    try {
        const { data } = await ReportsApi.getParams(reportName);
        return data.parameters;
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const runReportScript = async({ commit }, { report, params }) => {
    try {
        const generated_at = moment(Date.now()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        commit("addReportStatusCard", { status: "In-Progress", name: report.name , generated_at })
        commit('updateReportStatus', { status: { status: "In-Progress" }, report });
    
        const { data } = await ReportsApi.post({ "script-name": report.name, params });
        const status = { report_id: data.report_id, status: data.status, generated_at };

        commit('updateReportStatus', { status, report });
        commit("updateReportStatusCard", { status, report })
    }
    catch (error) {
        axiosErrorHandler(error);
    }
};

export const getReportFileData = async(context, url) => {
    try {
        const { data } = await axios.get(url, { responseType: 'blob' });
        return data;
    }
    catch (error) {
        axiosErrorHandler(error);
    }
};
