<template>
    <div class="c-drop-zone">
        <div
            v-bind="getRootProps()"
            :class="{
                'tw-bg-black/[.02] tw-border-solid': isDragActive,
                'tw-border-dashed': !isDragActive
            }"
            class="c-drop-zone__inner tw-border tw-border-black/24 tw-rounded tw-cursor-pointer hover:tw-bg-black/[.02] tw-h-40 tw-flex tw-items-center tw-justify-center tw-transition tw-select-none"
        >
            <input v-bind="getInputProps()" />

            <div class="tw-text-center">
                <q-icon name="cloud_upload" size="2.5em" class="tw-opacity-20" />
                <p>
                    Drop <b>{{ currentType.extension }}</b> file here or click to upload
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { useDropzone } from 'vue3-dropzone';

    const props = defineProps({
        type: {
            type: String,
            default: 'image',
            validator: value => ['image', 'spreadsheet'].includes(value)
        }
    });
    const emit = defineEmits(['onAccept', 'onReject']);

    const setType = type => {
        switch (type) {
            case 'spreadsheet': {
                return {
                    extension: '.xls, .xlsx',
                    mime: [
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ]
                };
            }
            default: {
                return {
                    extension: 'image',
                    mime: 'image/*'
                };
            }
        }
    };

    const currentType = ref(setType(props.type));

    const onDropRejected = errors => {
        emit('onReject', errors);
    };

    const onDropAccepted = files => {
        emit('onAccept', files);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropRejected,
        onDropAccepted,
        multiple: false,
        accept: currentType.value.mime
    });
</script>

<style lang="scss" scope>
    .q-field--error {
        .c-drop-zone {
            &__inner {
                border-color: var(--q-negative);
            }
        }
    }
</style>
