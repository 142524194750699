import { toLocal, fullFormatter } from 'src/helpers/date';

export const setMeshBridges = (state, meshBridges) => {
    state.meshBridges = meshBridges;
};

export const setMeshBridge = (state, meshBridge) => {
    const index = state.meshBridges.findIndex(n => n.bridge_id === meshBridge.bridge_id);

    if (index > -1) {
        state.meshBridges.splice(index, 1, meshBridge);
    } else {
        state.meshBridges.push(meshBridge);
    }
};

export const updateRemoteLocation = (state, location) => {
    const index = state.remoteLocations.findIndex(l => l.id === location.id);

    if (index > -1) {
        state.remoteLocations.splice(index, 1, Object.freeze(location));
    } else {
        state.remoteLocations.push(Object.freeze(location));
    }
};

export const updateWarehouse = (state, location) => {
    const index = state.warehouses.findIndex(l => l.id === location.id);

    if (index > -1) {
        state.warehouses.splice(index, 1, Object.freeze(location));
    } else {
        state.warehouses.push(Object.freeze(location));
    }
};

export const deleteRemoteLocation = (state, id) => {
    const index = state.remoteLocations.findIndex(l => l.id === id);

    if (index > -1) {
        state.remoteLocations.splice(index, 1);
    }
};

export const deleteWarehouse = (state, id) => {
    const index = state.warehouses.findIndex(l => l.id === id);

    if (index > -1) {
        state.warehouses.splice(index, 1);
    }
};

export const getWarehouses = (state, data) => {
    state.warehouses = data.map(item => Object.freeze(item));
};

export const getRemoteLocations = (state, data) => {
    state.remoteLocations = data.map(item => Object.freeze(item));
};

export const setNodeStates = (state, nodes) => {
    state.nodeStates = nodes
        .map(node =>
            Object.freeze({
                ...node,
                update_timestamp: fullFormatter(toLocal(node.update_timestamp))
            })
        )
        .sort((a, b) => new Date(b.update_timestamp).getTime() - new Date(a.update_timestamp).getTime());
};

export const setNodeState = (state, nodeState) => {
    const { label, params, index, data } = nodeState;

    if (data) {
        state.nodeStates.push(Object.freeze({ 
            ...data,
            update_timestamp: fullFormatter(toLocal(data.update_timestamp))
        }));
        return;
    }

    if (label.device_enable === false) {
        if (index < 0) return; 
        state.nodeStates.splice(index, 1);
        return;
    }

    const node = {
        ...label,
        update_timestamp: fullFormatter(toLocal(label.update_timestamp))
    };
    const filters = ['Warehouse', 'CRL', 'IN-TRANSIT'];

    if (index < 0) {
        state.nodeStates.push(Object.freeze(node));
        return;
    }

    if (filters.includes(params.filter) && state.nodeStates[index].location !== node.location) {
        state.nodeStates.splice(index, 1);
        return;
    }

    state.nodeStates.splice(
        index,
        1,
        Object.freeze({
            ...state.nodeStates[index],
            ...node,
            update_timestamp: label.update_timestamp !== '' ? fullFormatter(toLocal(label.update_timestamp)) : state.nodeStates[index].update_timestamp,
            description: label.description && label.description !== '' ? label.description : state.nodeStates[index].description,
        })
    );
};

export const clearNodeStates = state => {
    state.nodeStates = [];
};

export const setAlerts = (state, data) => {
    state.alerts = data
        .map(el =>
            Object.freeze({
                ...el,
                date_time: fullFormatter(toLocal(el.date_time))
            })
        )
        .sort((a, b) => new Date(b.date_time).getTime() - new Date(a.date_time).getTime());
};

export const setAlert = (state, data) => {
    state.alerts.push(
        Object.freeze({
            ...data,
            date_time: fullFormatter(toLocal(data.date_time))
        })
    );
};

export const clearAlerts = state => {
    state.alerts = [];
};

export const clearNewAlert = (state, data) => {
    const index = state.alerts.findIndex(el => el.alert_id === data.alert_id);

    if (index < 0) return;

    const oldItem = { ...state.alerts[index] };
    delete oldItem.isNew;
    state.alerts.splice(index, 1, Object.freeze(oldItem));
};

export const setReport = (state, message) => {
    let report;
    let primaryKey;

    switch (message.report_identifier) {
        case 'WSR':
            report = 'warehousesSummaryReport';
            primaryKey = 'Name';
            break;
        case 'CSR':
            report = 'crlSummaryReport';
            primaryKey = 'Name';
            break;
        case 'LRT':
            report = 'labelRoundTripsReport';
            primaryKey = 'LabelID';
            break;
    }

    if (!report) {
        console.warn(`report_identifier: ${message.report_identifier} is invalid!`);
        return;
    }

    const data = JSON.parse(message.payload);
    const index = state[report].findIndex(n => n[primaryKey] === data[primaryKey]);

    if (index > -1) {
        state[report].splice(index, 1, Object.freeze(data));
    } else {
        state[report].push(Object.freeze(data));
    }
};

export const clearReports = state => {
    state.warehousesSummaryReport = [];
    state.crlSummaryReport = [];
    state.labelRoundTripsReport = [];
};

export const setLabels = (state, data) => {
    state.labels = data;
};

export const setTrackers = (state, data) => {
    state.trackers = data;
};

export const setTransports = (state, data) => {
    state.transports = data;
};

export const setShipments = (state, data) => {
    state.shipments = data;
};

export const setMobileAnchors = (state, data) => {
    state.mobileAnchors = data.map(el => ({
        ...el,
        proximity_state : el.proximity_state.toLowerCase() !== 'within zone' && el.proximity_state.toLowerCase() !== 'within zone and dock door' ? '' : el.proximity_state.toLowerCase(),
        update_timestamp: fullFormatter(toLocal(el.update_timestamp))
    }));
};

export const setMobileAnchor = (state, data) => {
    const index = state.mobileAnchors.findIndex(n => n.mobile_anchor_id === data.mobile_anchor_id);
    const anchorfound = state.mobileAnchors.find(n => n.mobile_anchor_id === data.mobile_anchor_id)
    const anchor = {
        mobile_anchor_id: anchorfound.mobile_anchor_id,
        anchor_description: data.anchor_description?data.anchor_description:anchorfound.anchor_description,
        forklift_desc: data.forklift_desc?data.forklift_desc:anchorfound.forklift_desc,
        proximity_state: data.proximity_state!==undefined?data.proximity_state:anchorfound.proximity_state,
        zone_id: data.zone_id?data.zone_id:anchorfound.zone_id,
        dockdoor_id: data.dockdoor_id?data.dockdoor_id:anchorfound.dockdoor_id,
        x: data.x!==undefined?data.x:anchorfound.x,
        y: data.y!==undefined?data.y:anchorfound.y,
        z: data.z!==undefined?data.z:anchorfound.z,
        update_timestamp: fullFormatter(toLocal(data.update_timestamp?data.update_timestamp:anchorfound.update_timestamp))
    };

    if (index > -1) {
        state.mobileAnchors.splice(index, 1, anchor);
    } else {
        state.mobileAnchors.push(anchor);
    }
};

export const clearMobileAnchors = state => {
    state.mobileAnchors = [];
};

export const setLocalizationZones = (state, zones) => {
    state.zones = zones
}
export const setMobileTrackerHistory = (state, historyLocations) =>{
    const history = historyLocations.reverse();
    state.mobileTrackerHistory = history;
    // setting this unique trackers array to avoid markers stack issue on map
    state.uniqueMobileTrackers = history.filter((obj, index, self) => {
        // Check if the current object's coordinates are unique
        return (
            index ===
            self.findIndex(
            (innerObj) =>
                innerObj.lat === obj.lat && innerObj.lon === obj.lon
            )
        );
    })
}

export const setSensorData = (state, sensorData) =>{
    const data = sensorData;
    state.sensorData = data;
}

export const setEventsData = (state, eventsData) =>{
    const data = eventsData.reverse();
    state.eventsData = data;
}

export const setEventsFetched = (state, data) => {
    state.eventsFetched = data;
};

export const setSensorDataFetched = (state, data) => {
    state.sensorDataFetched = data;
};

export const setUniqueMobileTrackerHistory = (state, selectedLocation)=>{
    // setting this unique trackers array to avoid markers stack issue on map
    const updatedTrackers = state.uniqueMobileTrackers.map((tracker) => {
        if (tracker.lat === selectedLocation.lat && tracker.lon === selectedLocation.lon) {
          return {
            ...tracker,
            location_number: selectedLocation.location_number
          };
        }
        return tracker;
      });
    
      state.uniqueMobileTrackers = updatedTrackers;
}
export const setSelectedHistoryLocation = (state, location)=>{
    state.selectedHistoryLocation = location
}
// this mutation will get call on history item selected from sidebar
export const setSelectedHistoryLocationFromList = (state, location)=>{
    state.selectedHistoryLocationFromList = location
    
}
export const resetMobileTrackerHistory = (state)=>{
    state.selectedHistoryLocation = null;
    state.selectedHistoryLocationFromList = null;
    state.mobileTrackerHistory = [];
    state.uniqueMobileTrackers = [];
}

export const setSensorHistoryPopupDetail = (state, detail)=> {
    state.sensorHistoryPopupDetail = detail;
}

export const setSensorTempGraph = (state, detail)=> {
    state.sensorTempGraph = detail;
}

export const setSensorBatGraph = (state, detail)=> {
    state.sensorBatGraph = detail;
}

export const setHistoryFilter = (state, data) => {
    state.historyFilter = data
}

export const setLocalizationHistory = (state, data) => {
    state.zoneHistory = data
}

export const setZoneHistorySettings = (state, data) => {
    state.zoneHistorySettings = data
}
