import { defaultReport } from "src/services/reports";

export const setReportsData = (state, data) => {
    const reportsData = state.reportsData;
    const temp = data.scripts.map(reportName => {
        const index = reportsData.findIndex(el => el.name === reportName);
        if (index > -1) return reportsData[index];
        else return {
            ...defaultReport,
            name: reportName
        }
    });
    state.reportsData = temp;
};

export const setReportsStatus = (state, data) => {
    Object.keys(data).forEach(key => {
        const idx = state.reportsData.findIndex(item => item.name === key);
        if (idx > -1) {
            const item = data[key][0];
            state.reportsData[idx] = {
                ...state.reportsData[idx],
                report_id: item.report_id,
                status: item.status,
                generated_at: item.generated_at
            };
        }
    })
};

export const updateReportStatus = (state, { status, report }) => {
    report = { ...report, ...status }

    const index = state.reportsData.findIndex(el => el.name === report.name);
    state.reportsData.splice(index, 1, report)
}

export const setReportsNotifications = (state, data) => {
    const temp = [];
    Object.keys(data).forEach(name => {
        if (Array.isArray(data[name])) {
            data[name].forEach(execution => {
                temp.push({ ...execution, name })
            })
        }
    })

    temp.sort((a, b) => new Date(b.generated_at) - new Date(a.generated_at))

    state.reportsNotificationData = temp;
};

export const updateReportsNotifications = (state, report) => {
    const index = state.reportsNotificationData.findIndex(el => el.report_id === report.report_id);

    if (index > -1) {
        const newReportData = {
            ...state.reportsNotificationData[index],
            ...report
        }

        state.reportsNotificationData.splice(index, 1, newReportData)
    } else {
        const newReportData = {
            ...report
        }

        state.reportsNotificationData.unshift(newReportData)
    }
};

export const addReportStatusCard = (state, data) => {
    state.reportsStatusCardsData.push({ ...defaultReport, ...data });
};

export const updateReportStatusCard = (state, { status, report }) => {
    const index = state.reportsStatusCardsData.findIndex(el => el.name === report.name && el.generated_at === status.generated_at);

    if (index > -1) {
        state.reportsStatusCardsData[index] = { ...state.reportsStatusCardsData[index], ...status };
    }
};

export const deleteReportStatusCard = (state, report) => {
    const index = state.reportsStatusCardsData.findIndex(el => el.name === report.name && el.generated_at === report.generated_at && el.report_id === report.report_id);

    if (index > -1) {
        state.reportsStatusCardsData.splice(index, 1)
    }
};

export const setGeneratedReports = (state, data) => {
    state.generatedReports = Object.keys(data.report_files).map(key => {
        return {
            name: key,
            url: data.report_files[key],
        };
    });
};

export const setExecutionHistory = (state, data) => {
    for (const key in data) {
        if (key !== 'message') {
            state.executionHistory = data[key];
            return;
        }
    }

    state.executionHistory = [];
}

export const updateExecutionHistory = (state, report) => {
    const index = state.executionHistory.findIndex(el => el.report_id === report.report_id);

    if (index > -1) {
        const newExecutionData = {
            ...state.reportsNotificationData[index],
            ...report
        }

        state.executionHistory.splice(index, 1, newExecutionData)
    } else {
        const newExecutionData = {
            ...report
        }

        state.executionHistory.unshift(newExecutionData)
    }
};

export const clearExecutionHistory = (state) => {
    state.executionHistory = [];
}

export const setCustomReportConnection = (state, data) => {
    state.customReportConnection = data;
};
