import { boot } from 'quasar/wrappers';
import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

const api = axios.create({
    baseURL: process.env.DEV ? 'http://localhost:3000' : `/api`
    // baseURL: process.env.DEV ? 'http://192.168.0.141:3000' : `/api`
});

axiosRetry(api, {
    retries: 2,
    retryCondition: e => isNetworkOrIdempotentRequestError(e) && e.config.method !== 'head'
});

export default boot(({ app }) => {
    // for use inside Vue files (Options API) through this.$axios and this.$api

    app.config.globalProperties.$axios = axios;
    // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
    //       so you won't necessarily have to import axios in each vue file

    app.config.globalProperties.$api = api;
    // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
    //       so you can easily perform requests against your app's API
});

export { axios, api };
