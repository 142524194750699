import { boot } from 'quasar/wrappers';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueKonva from 'vue-konva';
import VueDatePicker from '@vuepic/vue-datepicker';

import { draggableDirective } from 'src/helpers/draggable';
import { resizableDirective } from 'src/helpers/resizeable';

import '@vuepic/vue-datepicker/dist/main.css'

export default boot(({ app, store }) => {
    const requireComponent = require.context('components/UI', false, /\.vue$/);

    requireComponent.keys().map(key => {
        const name = key.replace(/(\.\/|\.vue)/g, '');
        app.component(name, requireComponent(key).default);
    });

    app.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyCwFK5f94IUE-4QLzPei3QtvBWwkTAAFdM',
            libraries: ['geometry', 'drawing']
        }
    });
    app.directive('draggable', draggableDirective);
    app.directive('resizeable', resizableDirective);

    app.component('VueDatePicker', VueDatePicker);

    app.use(VueKonva);

    if (!window.Cypress) return;
    window.store = store;
});
