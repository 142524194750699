const bringToFront = (el) => {
  const highestZIndex = Array.from(document.querySelectorAll('.draggable')).reduce(
    (maxZIndex, element) => {
      const zIndex = parseInt(window.getComputedStyle(element).zIndex, 10) || 1;
      return Math.max(maxZIndex, zIndex);
    },
    0
  );
  el.style.zIndex = highestZIndex + 1;
};

const roundDown = (dim) => {
  return Math.floor(Number(dim.slice(0, -2))/10)*10
}

const generatedPositionKey = (popup) => {
  const rect = popup.getBoundingClientRect();
  if(popup.style.left === '') popup.style.left = `${rect.left}px`
  if(popup.style.top === '') popup.style.top = `${rect.top}px`

  return `${roundDown(popup.style.left)}:${roundDown(popup.style.top)}`;
}

const adjustPopupPositions = () => {
  const popups = Array.from(document.querySelectorAll('.draggable-offset')).sort((a, b) => a.style.zIndex - b.style.zIndex);

  const positions = {};

  popups.forEach((popup) => {
    const posKey = generatedPositionKey(popup)

    if (positions[posKey]) {
      positions[posKey].push(popup);
    } else {
      positions[posKey] = [popup];
    }
  });

  Object.values(positions).forEach((popupsAtPosition) => {
    if (popupsAtPosition.length > 1) {
      popupsAtPosition.forEach((popup, index) => {
        if (index === popupsAtPosition.length - 1) {
          popup.style.transform = 'translate(0, 0)';
        } else {
          const offset = -10 * (popupsAtPosition.length - 1 - index);
          popup.style.transform = `translate(${offset}px, ${offset}px)`;
        }
      });
    } else {
      popupsAtPosition[0].style.transform = 'translate(0, 0)';
    }
  });
};

export const draggableDirective = {
  mounted(el, { value: { offsetOverlaps, scope, initPosition } }) {
    el.classList.add('draggable');
    offsetOverlaps && el.classList.add('draggable-offset');
    el.style.zIndex = scope === 'global' ? 2000 : 100;
    el.style.boxShadow = 'rgba(0, 0, 0, 0.16) 0px 1px 4px';
    
    bringToFront(el);
    el.style.position = scope === 'global' ? 'fixed' : 'absolute';

    if (initPosition) {
      initPosition.split('-').forEach(po => {
        el.style[po] = po === 'right' ? '20px' : '8px'
      })
    }

    offsetOverlaps && adjustPopupPositions();

    let initX, initY, clickX, clickY;
    let isResizing = false; // Flag to check if resizing is in progress

    const mouseUp = () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', mouseUp);
      el.style.cursor = 'default';
      offsetOverlaps && adjustPopupPositions();
    };

    const mouseMove = (e) => {
      if (!isResizing && !e.target.classList.contains('no-drag')) {
        bringToFront(el);

        const restrictedX = scope === 'global' ? 0 : 320;
        const restrictedY = scope === 'global' ? 0 : 70;

        const maxX = window.innerWidth - restrictedX - (el.style.width?.includes('px') ? Number(el.style.width.slice(0, -2)) : el.offsetWidth);
        const maxY = window.innerHeight - restrictedY- (el.style.height?.includes('px') ? Number(el.style.height.slice(0, -2)) : el.offsetHeight);

        let newX = initX + e.clientX - clickX;
        let newY = initY + e.clientY - clickY;

        newX = Math.max(0, Math.min(newX, maxX));
        newY = Math.max(0, Math.min(newY, maxY));
        el.style.left = `${newX}px`;
        el.style.top = `${newY}px`;
      }
    };

    const mouseDown = (e) => {
      e.preventDefault();
      initX = el.offsetLeft;
      initY = el.offsetTop;
      clickX = e.clientX;
      clickY = e.clientY;
      el.style.cursor = 'grabbing';
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    };

    el.addEventListener('mousedown', (e) => {
      if (!isResizing) {
        mouseDown(e);
      }
    });

    el.addEventListener('resizestart', () => {
      isResizing = true;
    });

    el.addEventListener('resizeend', () => {
      isResizing = false;
    });

    el.addEventListener('dblclick', () => {
      bringToFront(el);
    });
  },
};
