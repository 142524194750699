import { v4 as uuidv4 } from 'uuid';

import {
    MeshBridgeApi,
    RemoteLocationApi,
    WarehouseApi,
    // AnchorApi,
    NodeApi,
    LabelApi,
    TrackerApi,
    TransportApi,
    ShipmentApi,
    LocalizationApi,
    LocalizationHistoryApi,
    MobileAnchorApi,
    MobileTrackerHistroyApi,
    DeviceHistoryApi,
    EventsApi,
} from 'src/services/api';
import { locationToFront, locationFromFront } from 'src/helpers/locations';
import { axiosErrorHandler } from 'src/services/errors';
import { toLocal, fullFormatter } from 'src/helpers/date';

export const getMeshBridges = async ({ commit }) => {
    try {
        const { data } = await MeshBridgeApi.get();
        commit('setMeshBridges', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getMeshBridge = async ({ commit }, id) => {
    try {
        const { data } = await MeshBridgeApi.get(id);
        commit('setMeshBridge', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const newMeshBridge = async (context, payload) => {
    try {
        await MeshBridgeApi.create(payload);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateMeshBridge = async (context, payload) => {
    try {
        const { bridge_id, ...body } = payload;
        await MeshBridgeApi.update(bridge_id, body);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteMeshBridge = async (context, id) => {
    try {
        await MeshBridgeApi.delete(id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getWarehouses = async ({ commit }) => {
    try {
        const response = await WarehouseApi.get();
        const data = response.data.map(location => locationToFront(location));
        commit('getWarehouses', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getRemoteLocations = async ({ commit }) => {
    try {
        const response = await RemoteLocationApi.get();
        const data = response.data.map(location => locationToFront(location));
        commit('getRemoteLocations', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteWarehouse = async ({ commit }, id) => {
    try {
        await WarehouseApi.delete(id);
        commit('deleteWarehouse', id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteRemoteLocation = async ({ commit }, id) => {
    try {
        await RemoteLocationApi.delete(id);
        commit('deleteRemoteLocation', id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const createWarehouse = async ({ commit }, payload) => {
    try {
        const { data } = await WarehouseApi.create(locationFromFront(payload));
        commit('updateWarehouse', {
            ...locationToFront(data),
            localization: false
        });
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const createRemoteLocation = async ({ commit }, payload) => {
    try {
        const { data } = await RemoteLocationApi.create(locationFromFront(payload));
        commit('updateRemoteLocation', locationToFront(data));
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateWarehouse = async ({ commit }, payload) => {
    try {
        const { id, name, localization, ...body } = payload;
        const { data } = await WarehouseApi.update(id, name, body);
        commit('updateWarehouse', {
            ...locationToFront(data),
            localization
        });
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateRemoteLocation = async ({ commit }, payload) => {
    try {
        const { id, name, ...body } = payload;
        const { data } = await RemoteLocationApi.update(id, name, body);
        commit('updateRemoteLocation', locationToFront(data));
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const setNodeStates = async ({ commit }, payload) => {
    try {
        const { data } = await NodeApi.get(payload);
        commit('setNodeStates', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getNodeStates = async (context, payload) => {
    try {
        const { data } = await NodeApi.get(payload);
        return data;
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const clearNodeStates = ({ commit }) => {
    commit('clearNodeStates');
};

export const addNode = async({ commit, state }, payload) => {
    const { label } = payload;
    const index = state.nodeStates.findIndex(n => n.node_id === label.node_id);
    payload.index = index;

    if (label.device_enable === true && index < 0) {
        const { data } = await NodeApi.get(['Label', [label.node_id]]);
        payload.data = data[0];
    }
    commit('setNodeState', payload);
};

export const clearAnchorStates = ({ commit }) => {
    commit('clearAnchorStates');
};

export const setAlert = async ({ commit }, payload) => {
    commit('setAlert', payload);

    if (payload.isNew) {
        setTimeout(() => {
            commit('clearNewAlert', payload);
        }, 3000);
    }
};

export const setReport = ({ commit }, message) => {
    commit('setReport', JSON.parse(message));
};

export const getLabels = async ({ commit }) => {
    try {
        const { data } = await LabelApi.get();
        commit('setLabels', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getLabel = async (context, id) => {
    try {
        const { data } = await LabelApi.get(id);
        return data[0];
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getTrackers = async ({ commit }) => {
    try {
        const { data } = await TrackerApi.get();
        commit('setTrackers', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getTracker = async (context, id) => {
    try {
        const { data } = await TrackerApi.get(id);
        return data[0];
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const newLabel = async (context, payload) => {
    try {
        await LabelApi.create(payload);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const newTracker = async (context, payload) => {
    try {
        await TrackerApi.create(payload);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateLabel = async (context, payload) => {
    try {
        await LabelApi.update({ labels: payload });
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateTracker = async (context, payload) => {
    try {
        await TrackerApi.update({ trackers: payload });
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteLabel = async (context, id) => {
    try {
        await LabelApi.delete(id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteTracker = async (context, id) => {
    try {
        await TrackerApi.delete(id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getTransports = async ({ commit }) => {
    try {
        const { data } = await TransportApi.get();
        commit('setTransports', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getTransport = async (context, id) => {
    try {
        const { data } = await TransportApi.get(id);
        return data[0];
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const newTransport = async (context, payload) => {
    try {
        await TransportApi.create(payload);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateTransport = async (context, payload) => {
    try {
        const { transport_id, ...body } = payload;
        await TransportApi.update(transport_id, body);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteTransport = async (context, id) => {
    try {
        await TransportApi.delete(id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getShipments = async ({ commit }) => {
    try {
        const { data } = await ShipmentApi.get();
        commit('setShipments', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getShipment = async (context, id) => {
    try {
        const { data } = await ShipmentApi.get(id);
        return data;
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const newShipment = async (context, payload) => {
    try {
        await ShipmentApi.create(payload);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateShipment = async (context, payload) => {
    try {
        const { asn_id, ...body } = payload;
        delete body.labels_count;
        await ShipmentApi.update(asn_id, body);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteShipment = async (context, id) => {
    try {
        await ShipmentApi.delete(id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getLocalizations = async () => {
    try {
        const { data } = await LocalizationApi.get();
        return data.map(item => ({
            ...item,
            update_timestamp: fullFormatter(toLocal(item.update_timestamp))
        }));
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getLocalization = async ({commit}, id) => {
    try {
        const { data } = await LocalizationApi.get(id);
        commit("setLocalizationZones", data.localization_zones_info)
        return data;
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const newLocalization = async (context, payload) => {
    try {
        await LocalizationApi.create(payload);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const updateLocalization = async (context, payload) => {
    try {
        const { localization_id, ...body } = payload;
        delete body.localization_name;
        delete body.plan_image;
        await LocalizationApi.update(localization_id, body);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const deleteLocalization = async (context, id) => {
    try {
        await LocalizationApi.delete(id);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getLocalizationHistory = async ({ commit }, payload) => {
    try {
        const { data } = await LocalizationHistoryApi.get(payload);
        const customizedData =  data.history ? data.history.map(item => ({
            ...item,
            update_timestamp: fullFormatter(new Date(item.update_timestamp)),
            id: uuidv4()
        })) : [];
        commit('setLocalizationHistory', customizedData);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const setMobileAnchors = async ({ commit }, warehouseID) => {
    try {
        const { data } = await MobileAnchorApi.get(warehouseID);
        commit('setMobileAnchors', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const setMobileAnchor = ({ commit }, payload) => {
    commit('setMobileAnchor', payload);
};

export const clearMobileAnchors = ({ commit }) => {
    commit('clearMobileAnchors');
};

export const getMobileTrackerHistory  =async ({ commit }, payload) => {
    try {
        const { data } = await MobileTrackerHistroyApi.get(payload);
        commit('setMobileTrackerHistory', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const getDeviceHistory = async ({ commit }, payload ) => {
    try {
        const { data } = await DeviceHistoryApi.get(payload);
        commit('setMobileTrackerHistory', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
}

export const getSensorData = async ({ commit }, payload ) => {
    try {
        commit('setSensorDataFetched', false);
        const { data } = await DeviceHistoryApi.get(payload);
        commit('setSensorData', data);
        commit('setSensorDataFetched', true);
    } catch (error) {
        axiosErrorHandler(error);
    }
}

export const getEventsData = async ({ commit }, payload ) => {
    try {
        if(payload.length) {
            commit('setEventsFetched', false);
            const { data } = await EventsApi.get(payload);
            commit('setEventsData', data);
            commit('setEventsFetched', true);
        } else {
            commit('setEventsData', [])
        }
    } catch (error) {
        axiosErrorHandler(error);
    }
}

export const setSensorHistoryPopupDetail = async ({ commit }, payload ) => {
        commit('setSensorHistoryPopupDetail', payload);
}

export const setSensorTempGraph = async ({ commit }, payload ) => {
    commit('setSensorTempGraph', payload);
}

export const setSensorBatGraph = async ({ commit }, payload ) => {
    commit('setSensorBatGraph', payload);
}


// export const beacon_ingress = (context, data) => {
//    return api.post('/beacon_ingress/', data).then(response => {
//          console.log('beacon ingress', response);
//    });
// }
