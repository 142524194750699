import script from "./DropZone.vue?vue&type=script&setup=true&lang=js"
export * from "./DropZone.vue?vue&type=script&setup=true&lang=js"

import "./DropZone.vue?vue&type=style&index=0&id=4da782f6&lang=scss&scope=true"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QField});
