<template>
    <div :class="{ invisible: !isShow }" class="flex flex-center" :style="`height: ${height}`">
        <q-spinner :color="color" :size="size" :thickness="thickness" />
    </div>
</template>

<script setup>
    import { ref, onUnmounted } from 'vue';

    const props = defineProps({
        height: {
            type: String,
            default: '200px'
        },
        color: {
            type: String,
            default: 'primary'
        },
        size: {
            type: String,
            default: '3em'
        },
        thickness: {
            type: Number,
            default: 2
        },
        delay: {
            type: Number,
            default: 0
        }
    });

    const isShow = ref(false);
    const timeout = setTimeout(() => {
        isShow.value = true;
    }, props.delay);

    onUnmounted(() => {
        clearTimeout(timeout);
    });
</script>
