import script from "./PageBreadcrumbs.vue?vue&type=script&setup=true&lang=js"
export * from "./PageBreadcrumbs.vue?vue&type=script&setup=true&lang=js"

import "./PageBreadcrumbs.vue?vue&type=style&index=0&id=60e77581&lang=scss"

const __exports__ = script;

export default __exports__
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBreadcrumbs,QBreadcrumbsEl});
