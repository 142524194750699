import { device_types } from "src/services/deviceTypes";
import { fullFormatter, toLocal } from "src/helpers/date";

export const setAccessToken = (state, accessToken) => {
    state.accessToken = accessToken;
};

export const setUserInfo = (state, data) => {
    state.userInfo = data;
};

export const updateLocationsType = (state, locationType) => {
    state.labelStatusLocationsType = locationType;
};

export const editLocation = (state, location) => {
    state.locationEdit = location;

    if (location) {
        state.locationsEditDraw = true;
    }
};

export const setLocationField = (state, field) => {
    state.locationEdit = {
        ...state.locationEdit,
        ...field
    };
};

export const toggleSidebarSettings = (state, data) => {
    state.sidebarSettings = data;
};

export const setDefaultParameters = (state, data) => {
    state.defaultParameters = data;
};

export const setNotification = (state, data) => {
    state.notifications.push(data);
};

export const deleteNotification = (state, id) => {
    const index = state.notifications.findIndex(n => n.id === id);
    if (index > -1) {
        state.notifications[index].close();
        state.notifications.splice(index, 1);
    }
};

export const setMapFilterType = (state, data) => {
    state.mapFilterType = data;
};

export const setMapFilterList = (state, data) => {
    const index = state.mapFilterList.findIndex(n => n === data);

    if (index > -1) {
        state.mapFilterList.splice(index, 1);
    } else {
        state.mapFilterList.push(data);
    }
};

export const setPreventMapFocus = (state, data) => {
    state.mapPreventFocus = data;
};

export const setMapLastPosition = (state, data) => {
    state.mapLastPosition = data;
};

export const setIndoorHistory = (state, data) => {
    state.indoorHistory = data;
};

export const setIndoorClusters = (state, data) => {
    state.indoorClusters = data;
};

export const setCurrentScroll = (state, val) => {
    state.savedScroll = val;
};

export const setProvisioningTableContext = (state, val) => {
    state.provisioningTableContext = { ...state.provisioningTableContext, ...val };
};

export const clearProvisioningTableContext = (state) => {
    state.provisioningTableContext = {};
};

export const setStreamInterruptionBanner = (state, val) => {
    if (!state.streamInterruptionBanner.sockets.includes(val.socket)) {
        state.streamInterruptionBanner.sockets.push(val.socket);
        if (val.close) {
            state.streamInterruptionBanner.close = val.close;
        }
    }
}

export const clearStreamInterruptionBanner = (state, val) => {
    if(state.streamInterruptionBanner) {
        const index = state.streamInterruptionBanner.sockets.findIndex(el => el === val.socket);
        if (index > -1) {
            state.streamInterruptionBanner.sockets.splice(index, 1);
    
            if (state.streamInterruptionBanner.sockets.length === 0) {
                state.streamInterruptionBanner?.close();
                state.streamInterruptionBanner.close = undefined;
            }
        }
    }
}

export const setBillingDetails = (state, data) => {
    const calculatedData = [
        {
            category: 'Label',
            count: 0,
            price: 0,
            totalCost: 0
        },
        {
            category: 'Cellular Tracker',
            count: 0,
            price: 0,
            totalCost: 0
        },
        {
            category: 'Total Cost',
            totalCost: 0
        }
    ]

    
    data?.devices?.forEach(el => {
        if (device_types.label.includes(el.device_type)) {
            calculatedData[0].count++;
            calculatedData[0].price = el.cost;
            calculatedData[0].totalCost += el.cost;
            el.category="Label";
        } else {
            calculatedData[1].count++;
            calculatedData[1].price = el.cost;
            calculatedData[1].totalCost += el.cost;
            el.category="Cellular Tracker";
        }
        
        calculatedData[2].totalCost += el.cost;
    })

    calculatedData[0].totalCost = calculatedData[0].totalCost.toFixed(2);
    calculatedData[1].totalCost = calculatedData[1].totalCost.toFixed(2);
    calculatedData[2].totalCost = calculatedData[2].totalCost.toFixed(2);

    const dataOverview = [calculatedData[0].totalCost, calculatedData[1].totalCost]

    state.billingDetails = {
        calculatedData,
        dataOverview,
        devices: data.devices
    }
}

export const setDeviceDetails = (state, data) => {
    const deviceInfo = {
        "Active since timestamp": fullFormatter(toLocal(data.active_since)),
        "Active seen timestamp": fullFormatter(toLocal(data.active_seen)),
        "Category": device_types.label.includes(data.device_type) ? "Label" : "Cellular Tracker",
        "Pricing": `$${data.pricing.price}/${data.pricing.period}`,
        "Total cost": `$${data.total_cost}`
    };

    state.deviceDetails = deviceInfo;
};
