import jwt_decode from 'jwt-decode';
import { getUtcTimesForCurrentMonth } from 'src/helpers/date';
import { BillingsApi, DefaultParametersApi } from 'src/services/api';
import { axiosErrorHandler } from 'src/services/errors';

export const updateAccessToken = ({ commit }, payload) => {
    let userInfo = null;
    if (payload) {
        const { email, name, picture } = jwt_decode(payload);
        userInfo = {
            email,
            name,
            picture
        };
        localStorage.setItem('authEmail', email);
    } else {
        localStorage.removeItem('authEmail');
    }

    commit('setAccessToken', payload);
    commit('setUserInfo', userInfo);
};

export const toggleSidebarSettings = ({ commit }, payload) => {
    commit('toggleSidebarSettings', payload);
};

export const getDefaultParameters = async ({ commit }) => {
    try {
        const { data } = await DefaultParametersApi.get();
        commit('setDefaultParameters', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const setDefaultSettings = async ({ commit }, payload) => {
    try {
        const { data } = await DefaultParametersApi.update(payload);
        commit('setDefaultParameters', data);
    } catch (error) {
        axiosErrorHandler(error);
    }
};

export const setNotification = ({ dispatch, commit, state }, data) => {
    commit('setNotification', data);

    if (state.notifications.length > 3) {
        dispatch('deleteNotification', state.notifications[0].id);
    }
};

export const deleteNotification = ({ commit }, id) => {
    commit('deleteNotification', id);
};

export const getBillingDetails = async ({ commit }) => {
  try {
    const { firstDayUTC: startTime, lastDayUTC: endTime } = getUtcTimesForCurrentMonth();
    const { data } = await BillingsApi.getCurrentMonthCost({start_time: startTime, end_time: endTime})

    commit("setBillingDetails", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

export const getDeviceDetails = async ({ commit }, { device_id }) => {
  try {
    const { firstDayUTC: startTime, lastDayUTC: endTime } = getUtcTimesForCurrentMonth();
    const { data } = await BillingsApi.getDeviceDetails({
      start_time: startTime,
      end_time: endTime,
      device_id,
    });
    commit("setDeviceDetails", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};
